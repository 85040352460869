import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios,axios);
Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.use(ElementUI)


Vue.directive('resize', {
  bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
    let width = '', height = '';
    function isReize() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value({width:style.width,height:style.height});  // 关键(这传入的是函数,所以执行此函数)
      }
      width = style.width;
      height = style.height;
    }
    el.__vueSetInterval__ = setInterval(isReize, 300);
  },
  unbind(el) {
    clearInterval(el.__vueSetInterval__);
  }
});
// 路由拦截省去每次你都去页面判断是否登录和权限的麻烦
router.beforeEach((to, from, next) => {
  if(to.path.toLowerCase() === '/login'){
      next()
  }else{
      if(!sessionStorage.getItem('userinfo') ) {
        next({path:'/login'}) 
        return
      }else{
        next()
      }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
