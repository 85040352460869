import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//相当于全局变量，和缓存不一样
export default new Vuex.Store({
    state: {
    },
    getters: { //可以理解为computed ，对数据进行计算,返回全局变量状态
    },
    mutations: {//对数据的同步更改
    },
    actions: {},
    modules: {}
})
