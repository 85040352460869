import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//每个菜单对应的页面配置
const routes = [
  {
    path: '/login',
    name: '登录',
    meta: {
      title: '睿互动知识图谱'
    },
    component:  () => import('../views/Login/Login.vue')
  },
  //下面路径映射分了children和菜单的分级无关，只是说明下面的所有路径映射都是再/首页中的，所以写道了childern里
  {
    path: '/',
    name: '首页',
    meta: {
      title: '睿互动知识图谱'
    },
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/knowledge/viewknowledge',
        name: '知识概览',
        component: () => import('../views/knowledge/viewknowledge.vue'),
      },
      {
        path: '/knowledge/viewknowledgeuser',
        name: '个性化学习推荐',
        component: () => import('../views/knowledge/viewknowledgeuser.vue'),
      },
      // {
      //   path: '/knowledge/viewknowledgetest',
      //   name: '3d',
      //   component: () => import('../views/knowledge/viewknowledgetest.vue'),
      // },
    ]
  },

]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
